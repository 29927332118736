<template>
    <div class="vendorHome">
        <Header :type="type" :questionOpen="questionOpen" @closeQuestionOpen="questionOpen=false"/>
        <PopAlert v-if="popalert" @closeAlert="closeAlert" :type="popAlertType" @confirm="confirm" />
        <div class="vendorHomeContainer">
            <VendorSide :sideMenu="menuStatus" @changeSide="changeSide"/>
            <div class="vendorHomeContent">
                <img class="blockEmpty" src="@/assets/img/blockEmpty.svg" v-if="exhibition.length==0" alt="">
                <!-- <div class="vendorInfo" v-if="menuStatus==1">
                    <div class="avatarWrap">
                            <div class="userAvatar" :style="{backgroundImage: 'url(' + userImg + ')'}">
                            </div>
                    </div>
                    <ul>
                        <li class="nameWrap">
                            <h2>{{vendor.companyName}}</h2>
                            <button class="btn" :class="{down:editMouse}" @mousedown="editMouse=true" @mouseup="editMouse=false" @click="$router.push({name:'VendorEdit'})">Edit</button>
                        </li>
                        <li><div class="iconWrap"><img src="@/assets/img/icon/mailIcon.svg" alt=""></div>{{vendor.email}}</li>
                        <li><div class="iconWrap"><img src="@/assets/img/icon/phoneIcon.svg" alt=""></div>{{vendor.phone}}</li>
                        <li><div class="iconWrap"><img src="@/assets/img/icon/locationIcon.svg" alt=""></div>{{vendor.address}}</li>
                        <li><div class="iconWrap" v-if="vendor.link"><img src="@/assets/img/icon/linkIcon.svg" alt=""></div>{{vendor.link}}</li>
                        <li>
                            <div class="infoTrigger">
                                <p>Allow visitors to see your contact information <img class="btn" @click="questionOpen=true" src="@/assets/img/icon/question.svg" alt=""></p>
                                <el-checkbox v-model="checked" @change="checkChange" >Show my contact information</el-checkbox>
                            </div>
                        </li>
                    </ul>
                </div> -->
                <Account @changeEditStatus="changeEditStatus" :editStatus="editStatus" :checked="checked" v-if="menuStatus==2" ref="account"/>
                <div class="myBlock" v-if="menuStatus==1">
                    <h2 style="text-align: left;margin: 30px auto 16px;font-size: 20px;line-height: 1.5;">My Block</h2>
                    <ul>
                        <li v-for="(item,index) in exhStatus" :class="{active:item.txt==blockTab}" :key="'exhStatus'+index" @click="blockTab=item.txt">{{item.txt}}<span>{{item.count}}</span></li>
                    </ul>
                    <div class="exhibitionInfo">
                        <div class="empty mb20" :class="{btn:exhibition.length<3}" v-if="blockTab=='All Blocks'" @click="goEdit('empty')">
                            <p v-if="exhibition.length<3"><span></span>Create a new block</p>
                            <div class="upgrade" v-if="exhibition.length>=3">
                                <p>You have created 3 blocks( files).To upgrade the Professional plan to create more block</p>
                                <button class="submit" @click="upgrade">Upgrade</button>
                                <p class="error">The block in the trash is also included</p>
                            </div>
                        </div>
                        <div class="exhibitionData btn mb20" v-for="(item,index) in exhibition" :key="'exh'+index" :class="{active:activeExh==index}">
                            <div>
                                <div class="exhImg" @click="activeExh=index" v-if="item.coverUrl!=''" :style="{backgroundImage: `url(${HostUrl}${item.coverUrl})`}"></div>
                                <div class="exhImg emptyImg" @click="activeExh=index" v-if="item.coverUrl==''"></div>
                                <div class="Info">
                                    <h3>{{item.title==''?`Block${index+1}`:item.title}}</h3>
                                    <!-- <p>{{item.introduction}}</p> -->
                                    <!-- <p class="status" :class="{closed:item.status=='draft'}">{{item.status!='draft'?'Opening':'Offline'}}</p> -->
                                    <el-form class="status">
                                        <el-form-item>
                                            <el-switch v-model="exhibitionStatus[index]" @change="updateData(index)"></el-switch>
                                        </el-form-item>
                                    </el-form>
                                    <div class="tagWrap">
                                        <label for="" v-for="(tag,index1) in item.tags" :key="'key'+index1">{{tag.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="bg" @click="activeExh=null">
                                <button @click="goEdit('edit',index)">Edit Block</button>
                            </div>

                            <!-- more -->
                            <!-- <div class="moreContainer" :class="{active:exhMoreToggle[index]}" @click="exhMoreToggleMethod(true,index)">
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="message" v-if="exhMoreToggle[index]">
                                    <div class="poly"></div>
                                    <p @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1" :style="hoverIcon==1?{color:`#D2D2D2`}:{}">Restore</p>
                                    <p @mouseenter="hoverIcon=2" @mouseleave="hoverIcon=-1" :style="hoverIcon==2?{color:`#D2D2D2`}:{}" @click="popalert=true">Delete forever </p>
                                </div>
                            </div> -->
                            <!--  -->
                            <!-- <el-form>
                                <el-form-item>
                                    <el-switch @change="changeStatus(index)" v-model="exhibitionStatus[index]"></el-switch>
                                </el-form-item>
                            </el-form> -->

                            <!-- <div class="editWrap btn" @click="goEdit('edit',index)">
                                <img src="@/assets/img/enterExh.svg" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bg" v-show="alert">
            <div class="alertWrap" :class="{exhOpen:open=='publish'}">
                <img class="btn" @click="alert=false" src="@/assets/img/icon/cross.svg" alt="">
                <h4 v-if="open=='publish'">Your exhibition is now online.</h4>
                <h4 v-if="open=='draft'">Your exhibition is now offline.</h4>
                <p v-if="open=='draft'">* Visitors cannot visit your exhibition now.</p>
                <p v-if="open=='publish'">* Visitors can visit your exhibition now.</p>
                <button class="submit" @click="alert=false">OK</button>
            </div>
        </div>
    </div>
</template>
<script>
import headIcon from "@/assets/img/icon/userIcon.svg";
import Header from '@/components/Header'
import VendorSide from '@/components/Vendor/HomeSideMenu'
import Account from '@/components/Vendor/Account'
import PopAlert from '@/components/Vendor/PopAlert'
import exhImg from '@/assets/img/exhImg/image.png'
import {SwitchEl } from 'element-ui';
import { mapGetters,mapActions } from "vuex";
import {getVendorExh,createSellerExh,getSellerExhDetail,HostUrl,updateSellerExhData,uploadSellerInfoOpen} from '@/api';
export default {
    name:'Vendor',
    data(){
        return{
            type:'normal_m',
            // exhData:[
            //     {
            //         img:exhImg,
            //         name:'BKhole',
            //         description:'Now, you can communicate with fellow creators in the Figma Community via comments. Give in-context feedback on files. Suggest feature ideas for plugins. Ask a fellow creator how they did something. Or, simply share some love.',
            //         tags:['Cultural and Creative','Design','Tag'], 
            //     }
            // ],
            open:false,
            alert:false,
            rooms:[
                {
                    name:'First Room',
                    active:true
                },
                {
                    name:'First Room',
                    active:true
                },
                {
                    name:'First Room',
                    active:true
                }
            ],
            manageRoomPop:false,
            HostUrl:HostUrl,
            exhibitionStatus:[],
            infoToggle:true,
            checked:true,
            questionOpen:false,
            editMouse:false,
            menuStatus:1,
            exhStatus:[
                {
                    txt:'All Blocks',
                    count:0,
                },
                {
                    txt:'Online',
                    count:0,
                },
                {
                    txt:'Offline',
                    count:0,
                }
            ],
            activeExh:null,
            hoverIcon: -1,
            exhMoreToggle:[],
            blockTab:'All Blocks',
            popalert:false,
            popAlertType:'delete',
            dataChanged:false,
            editAccountAlert:false,
            newPageIdx:null,
            editorStatus:false,
            editStatus:false
        }
    },
    components:{
        Header,SwitchEl,VendorSide,PopAlert,Account
    },
    mounted(){
        if(this.exhData!=[]){
            this.exhData.forEach(item => {
                if(item.status=="publish")
                    this.exhibitionStatus.push(true)
                else
                    this.exhibitionStatus.push(false)
            });
        }
        
    },
    methods: {
        ...mapActions(["setExhData","setEditingExh"]),
        changeEditStatus(bool){
            this.editStatus = bool; 
        },
        async goEdit(type,index){
            if(type=='empty'){
                if(this.exhibition.length<3){
                    this.popalert = true;
                    this.popAlertType = 'createExh';
                }
                return
            }else if(type=='exhFulled'){
            }else{
                let res = await getSellerExhDetail(this.exhData[index]._id)
                this.setEditingExh(res.data.data)
            }
            //this.$router.push({name:'ExhibitionEdit',query:{exh:index}})
            window.location.href = location.protocol+'//'+window.location.host+'/vendor/exhibitionedit?exh='+index;
        },
        async changeExhName(name,exhData){
            let param = {
                signboardUrl: exhData.signboardUrl,
                coverUrl: exhData.coverUrl,
                title: name,
                introduction: exhData.introduction,
                tags: exhData.tags, // JSON string array
                status: exhData.status  // publish or draft
            }
            let res = await updateSellerExhData(param,exhData._id)
            this.closeAlert();
            this.$parent.getExh()
        },
        async createExh(name){
            let res = await createSellerExh()
            this.changeExhName(name,res.data.data)
        },
        async changeStatus(idx){
            let data = this.exhData[idx]
            let tagKeyArray = []
                data.tags.forEach((item,index)=>{
                    tagKeyArray.push(item.key)
                })
            let newStatus = this.exhibitionStatus[idx]?'publish':'draft'
            this.open = newStatus
            let param = {
                signboardUrl: data.signboardUrl,
                coverUrl: data.coverUrl,
                title: data.title,
                introduction: data.introduction,
                tags: tagKeyArray, // JSON string array
                status: newStatus // publish or draft
            }
            let res = await updateSellerExhData(param,data._id)
            this.alert = true
            this.$parent.getVenderProfileInfo();
        },
        async checkChange(){
            let param = {
                showDetail:this.checked
            }
            let res = await uploadSellerInfoOpen(param)
        },
        exhMoreToggleMethod(bool,idx){
            let oldVal = this.exhMoreToggle[idx];
            this.exhMoreToggle.forEach((item,idx)=>{
                this.$set(this.exhMoreToggle, idx, false);
            });
            if(bool){ this.$set(this.exhMoreToggle, idx, !oldVal);}

        },
        changeSide(idx){
            if(this.menuStatus==2&&this.editStatus){this.popalert = true;this.popAlertType='editAccount';this.newPageIdx=idx;return}
            else if(idx==0){this.$router.push("/");return}
            this.menuStatus = idx;
        },
        confirm(type,name){
            if(type=='createExh'){
                this.createExh(name);
            }
            else if(type=='editAccount'){
                this.$refs.account.updateData();
                this.editStatus = false;
                this.menuStatus = this.newPageIdx;
            }
        },
        closeAlert(type){
            if(type=='editAccount'){this.menuStatus = this.newPageIdx;this.editStatus = false;}
            this.popalert = false;
        },
        async getExh(){
            let res = await getVendorExh()
            this.setExhData(res.data.data)
        },
        async updateData(idx){
            let tagArray = [];
            let status = this.exhibitionStatus[idx] ? 'publish':'draft'
            this.exhData[idx].tags.forEach((item,index)=>{
                tagArray.push(item.key)
            })
            let param = {
                signboardUrl: this.exhData[idx].signboardUrl,
                coverUrl: this.exhData[idx].coverUrl,
                title: this.exhData[idx].editName,
                introduction: this.exhData[idx].introduction,
                tags: tagArray, // JSON string array
                status: status // publish or draft
            }
            let res = await updateSellerExhData(param,this.exhData[idx]._id)
            this.getExh();
            // if(type=='status'){
            //     this.$message({
            //         showClose: true,
            //         dangerouslyUseHTMLString: true,
            //         message: this.open ? '<p style="color:#fff">Your block is online now</p>' : '<p style="color:#fff">Your block is offline now</p>',
            //         type: 'success',
            //         duration: 1500,
            //     });
            // }
        },
        upgrade(){
            this.popalert = true;
            this.popAlertType = 'upgrade';
        }
    },
    computed:{
        ...mapGetters(["vendor","exhData"]),
        userImg(){
            if(this.vendor.avatarUrl)
                return this.vendor.avatarUrl
            else 
                return headIcon
        },
        exhibition(){
            let newArr = [] ;
            this.exhMoreToggle = [];
            this.exhData.forEach((item)=>{
                if(this.blockTab=='All Blocks'){
                    newArr.push(item);
                    this.exhMoreToggle.push(false);
                }else if(this.blockTab=='Online'){
                    if(item.status!='draft'){newArr.push(item);this.exhMoreToggle.push(false);}
                }else{
                    if(item.status=='draft'){newArr.push(item);this.exhMoreToggle.push(false);}
                }
            });
            return newArr;
        }
    },
    watch:{
        exhData(newV,oldV){
            this.exhibitionStatus = []
            if(newV!=[]){
                this.exhStatus[0].count = this.exhStatus[1].count = this.exhStatus[2].count = 0;
                newV.forEach(item => {
                    this.exhStatus[0].count++;
                    if(item.status=="publish")
                    {
                        this.exhibitionStatus.push(true);
                        this.exhStatus[1].count++;
                    }
                    else{
                        this.exhibitionStatus.push(false);
                        this.exhStatus[2].count++;
                    }
                });
            }
        },
        vendor(newV,oldV){
            this.checked = this.vendor.showDetail
        },
        $route(to, from) {
            // 对路由变化作出响应...
            this.getExh();
        }
        
    }  
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .vendorHome{
        min-width: 900px;
        .vendorHomeContainer{
            display: flex;
            position: relative;
            z-index: 1;
            .blockEmpty{
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
            }
        }
        .bg{
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-color: rgba(0,0,0,0.7);
            z-index: 99;
            .alertWrap{
                position: absolute;
                top:45%;
                background-color: #fff;
                margin: 0 auto;
                width: 513px;
                left: 50%;
                transform: translate(-50%,-50%);
                padding: 10px 10px 30px 0;
                border-radius:12px;
                border:3px solid $purple;
                img{
                    width: 3.5%;
                    display: block;
                    margin: 0 0 0 auto;
                }
                h4{
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 17px;
                    line-height: 1.5;
                    margin-bottom: 30px;
                    margin-top: 0;
                }
                button{
                    line-height: 2.95;
                    padding:0 40px;
                    border-radius: 7px;
                }
                &.exhOpen{
                    p{
                        margin-bottom: 59px;
                    }
                }
            }
            .manageRoomPop{
                border-radius: 9px;
                overflow: hidden;
                width: 24.3%;
                position: relative;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                background-color: #fff;
                min-width: 400px;
                padding-bottom: 1%;
                h4{
                    font-size: $font*20;
                    position:relative;
                    background-color: #373B53;
                    line-height: 2.3;
                    color:#fff;
                    margin-top: 0;
                    margin-bottom: 7%;
                    @include for-size(desktop-only) {
                        font-size: 14px;
                    }
                    span{
                        background-image:url('~@/assets/img/icon/crossW.svg');
                        background-size: 100%;
                        width: 3%;
                        padding-bottom: 3%;
                        position: absolute;
                        top: 50%;
                        right: 3%;
                        transform:translateY(-50%);
                    }
                }
                p{
                    font-size: $font*14;
                    width: 55%;
                    margin: 0 auto 7%;
                    &:nth-of-type(2){
                        font-size: 14*$font;
                        color:$purple;
                        text-decoration: underline;
                        margin: 0 auto 5%;
                        @include for-size(desktop-only) {
                            font-size: 12px;
                        }
                    }
                }
                .inputWrap{
                    display: flex;
                    width: 55%;
                    margin: 0 auto 8%;
                    position:relative;
                    border-color: #B2B1B1;
                    .el-form{
                        position: absolute;
                        right: -24%;
                    }
                    ::v-deep .el-switch.is-checked .el-switch__core{
                        border-color: $purple;
                        background-color: $purple;
                    }
                    ::v-deep .el-switch.is-checked{
                        &:before{     
                            color:#fff;
                        }
                    }
                    ::v-deep .el-switch .el-switch__core{
                        border-color: #C7C7C8;
                        background-color: #C7C7C8;
                    }
                    input{
                        font-size: $font*16;   
                        line-height: 2.3;
                        width: 100%;
                        padding: 2px 13px;
                        border-color:#B2B1B1;
                        color:#282828;
                        &.lock{
                            color:#B2B1B1;
                            border-color:#B2B1B1;
                        }
                        @include for-size(desktop-only) {
                            font-size: 12px;
                        }
                    }
                    img{
                        position: absolute;
                        width: 11px;
                        left: -12%;
                    }
                }

                .submit{
                    font-size: $font*16;
                    width:55%;
                    margin: 0 auto 6%;
                    border-radius: 5px;
                    line-height: 2.5;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                        border-radius: 7px;
                    }
                }
                
            }
        }
        .emptyImg{
            background-image:url(~@/assets/img/icon/userIcon.svg);
        }
        .vendorHomeContent{
            padding-left: 125px;
            background-color: #EFEFEF;
            height:calc(100vh - 64px);
            width:calc(100vw - 165px);
            min-width: 735px;
            overflow: auto;
            position: relative;
        }
        .vendorInfo{
            margin-left: 15%;
            display: flex;
            padding-top: 75px;
            width: 70%;
            margin:0 auto 4%;
            position: relative;
            flex-wrap: wrap;
            max-width:980px;
            min-width: 900px;
        }
        .avatarWrap{
            width: 130px;
            position:relative;
            margin-right: 60px;
            img{
                position:absolute;
                top: 67%;
                right: -3%;
            }
        }
        .userAvatar{
            padding-bottom:157px;
            background-size: 100%;
            width: 157px;
            position:relative;
            border-radius: 100%;
            background-position: center;
            background-color: #fff;
            border:5px $purple solid;
        }
        .nameWrap{
            text-align: left;
            margin-right: 60px;
            display: flex;
            margin: 5px 0 20px;
            h2{
                font-size: 24px;
                margin: 5px 0 20px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                max-width: 300px;
                overflow: hidden;
                margin: 0 10px 0 0;
            }
            button{
                background-color: #fff;
                color:$gray;
                border:1px solid $gray;
                font-size: 12px;
                line-height: 2;
                font-weight: 500;
                border-radius: 4px;
                padding: 0 9px;
                &:before{
                    content:url(~@/assets/img/icon/pen_w.svg);
                    display: inline-block;
                    margin-right: 5px;
                }
                &:hover{
                   background-color: #F1F1F1; 
                }
            }
        }
        ul{
            text-align: left;
            margin-top: 10px;
            li{
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 15px;
                color:#767676;
                .iconWrap{
                    text-align: center;
                    width: 18px;
                    margin-right: 15px;
                }
                img{
                    width:18px;
                    display: inline-block;
                    vertical-align: middle
                }
                &:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4){
                        img{
                            width:15px;
                        }
                }
            }
        }
        .empty{
            background-color: #fff;
            width: 280px;
            height:260px;
            margin: 0 30px 0 0;
            border-radius:4px;
            justify-content: center;
            display: flex;
            align-items: center;
            pointer-events: none;
            @include blockShadow;
            &.btn{
                pointer-events: inherit;
            }
            p{
                font-size: 14px;
                color:#16192C;
                vertical-align: middle;
                display: inline-block;
                span,span:before{
                    display: inline-block;
                    width:9px;
                    height:2px;
                    border-radius: 1px;
                    background-color:#16192C;
                }
                span{
                    position:relative;
                    margin-right:10px;
                    vertical-align: middle;
                    &:before{
                        content:'';
                        position:absolute;
                        left:50%;
                        transform:translateX(-50%) rotate(90deg);
                    }
                }
            }

        }
        .infoTrigger{
            //position: absolute;
            // right: 2%;
            // top: 32%;
            text-align: left;
            p{
                color:#282828;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
            img{
                margin-left: 10px;
            }
            ::v-deep .el-switch .el-switch__core{
                width: 92px !important;
                height: 35px;
                border-radius: 30px;
                border-color: #C7C7C8;
                background-color: #C7C7C8;
            }
            ::v-deep .el-switch.is-checked .el-switch__core{
                border-color: $purple;
                background-color: $purple;
            }
            ::v-deep .el-switch{
                &:before{
                    content:'OFF';
                    position: absolute;
                    right:15px;
                    font-size: 14px;
                    top: 49%;
                    transform: translateY(-50%);
                    color:$gray;
                    z-index: 2;
                }
            }
            ::v-deep .el-switch.is-checked{
                &:before{
                    content:'ON';
                    position: absolute;
                    right:auto;
                    left:15px;
                    font-size: 14px;
                    top: 49%;
                    transform: translateY(-50%);
                    color:#fff;
                    z-index: 2;
                }
            }
            ::v-deep .el-switch .el-switch__core::after{
                height: 31px;
                width: 31px;
            }
            ::v-deep .el-switch.is-checked .el-switch__core::after{
                margin-left: -32px;
            }
            ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: $purple;
                border-color: $purple;
            }
            ::v-deep .el-checkbox__label,::v-deep .el-checkbox__input{
                color:#282828;
                font-weight: 500;
            }
            ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
                color:#282828;
            }
        }
        .myBlock{
            background-color: #EFEFEF;
            ul{
                display: flex;
                li{
                    font-size: 14px;
                    margin-right:20px;
                    color:#767676;
                    cursor: pointer;
                    span{
                        width:17px;
                        height: 19px;
                        text-align: center;
                        margin-left:10px;
                        background-color: #E3E3E3;
                        display: inline-block;
                    }
                    &.active{
                        color:#333333;
                        span{
                            background-color: #D8DBFF;
                        }
                    }
                }
            }
        }
        .exhibitionInfo{
            padding-bottom: 5%;
            max-width:980px;
            min-width: 900px;
            display: flex;
            flex-wrap: wrap;
            .upgrade{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 92%;
                padding:6% 7% 0 ;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.25;
                    &.error{
                        font-size: 12px;
                        color:#FB4A93;
                    }
                }
                button{
                    width:96px;
                    line-height: 2.2;
                    border-radius:6px;
                    margin:0 auto;
                    font-size:14px;
                }
            }
        }
        .exhibitionData{
            width: 280px;
            height:260px;
            //padding: 46px;
            position: relative;
            margin:0 30px 0 0;
            background-color:#fff;
            align-items: flex-start;
            //margin-bottom: 3%;
            border-radius:4px;
            overflow: hidden;
            padding:10px 10px 0;
            @include blockShadow;
            &.active{
                border:1px solid $purple;
                .bg{
                    display: block;
                }
            }
            .bg{
                position:absolute;
                background-color: transparent;
                display: none;
                button{
                    color:$purple;
                    border:1px solid $purple;
                    font-size: 14px;
                    width:100px;
                    border-radius: 36px;
                    padding: 11px 0;
                    position: relative;
                    top:50%;
                    background-color: #fff;
                    transform:translateY(-50%);
                }
            }
            .exhImg{
                padding-bottom: 164px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #E2E2E2;

            }
            .Info{
                text-align: left;
                overflow: hidden;
                padding: 15px 10px 0;
                h3{
                    font-size: 16px;
                    margin: 0 0 15px;
                    font-weight: 600;
                    line-height: 1;
                }
                p{
                    //width: 710px;
                    width: 100%;
                    line-height: 2;
                    margin-bottom: 50px;
                    //padding-right: 10%;
                    text-align: justify;
                    text-justify:inter-ideograph;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                .status{
                    font-size:12px;
                    position:absolute;
                    right: 56px;
                    top: 176px;
                    width:35px;
                    // &:before{
                    //     content:'';
                    //     display: inline-block;
                    //     width: 10px;
                    //     height: 10px;
                    //     margin-right: 5px;
                    //     background-color: #27CA40;
                    //     border-radius: 100%;
                    // }
                    // &.closed{
                    //     &:before{
                    //         background-color:#FB4A93;
                    //     }
                    // }
                    .el-form-item{
                        margin-bottom: 0;
                    }
                    ::v-deep .el-switch .el-switch__core{
                        //width: 92px !important;
                        height: 15px;
                        border-radius: 30px;
                        border-color: #C7C7C8;
                        background-color: #C7C7C8;
                        width: 100% !important;
                        // @include for-size(desktop-only) {
                        //     height: 30px;
                        // }
                        // @include for-size(tablet-landscape-only){
                        //     width: 445px;
                        // }
                    }

                    ::v-deep .el-switch.is-checked .el-switch__core{
                        border-color: #27CA40;
                        background-color: #27CA40;
                    }
                    ::v-deep .el-switch{
                        width: 100%;
                        &:before{
                            content:'Offline';
                            position: absolute;
                            font-size: 12px;
                            top: 49%;
                            transform: translateY(-50%);
                            z-index: 2;
                            left:auto;
                            right: -120%;
                            color:#333333;
                        }
                    }

                    ::v-deep .el-switch.is-checked{
                        &:before{
                            content:'Online';
                        }
                    }
                    ::v-deep .el-switch .el-switch__core::after{
                        height: 11px;
                        width: 11px;
                        // @include for-size(desktop-only) {
                        //     height: 26px;
                        //     width: 26px;
                        // }
                    }
                    ::v-deep .el-switch.is-checked .el-switch__core::after{
                        margin-left: -13px;
                        // @include for-size(desktop-only) {
                        //     margin-left: -27px;
                        // }
                    }
                }
            }
            .tagWrap{
                display: flex;
                label{
                    background-color: #D8DBFF;
                    color:#333333;
                    font-size: 11px;
                    line-height: 2;
                    padding: 0 10px;
                    margin-right: 10px;
                    border-radius:50px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    width: 57px;
                }
            }
            .moreContainer{
                position:absolute;
                right:10px;
                height:16px;
                width:16px;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-radius: 1px;
                bottom: 10px;
                &:hover,&.active{
                    background-color: #D8DBFF;
                }
                .dot{
                    width:2px;
                    height:2px;
                    border-radius:100%;
                    background-color: #B1AEAE;
                }
                .message{
                    position:absolute;
                    background-color: #FFFFFF;
                    border-radius:4px;
                    padding: 0px 9px;
                    width: 98px;
                    right: -5px;
                    bottom: 33px;
                    @include blockShadow;
                    .poly{
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 11.5px 13px 11.5px;
                        border-color: transparent transparent #FFFFFF transparent;
                        position:absolute;
                        transform: rotate(180deg);
                        right: 3px;
                        bottom: -12px;
                    }
                    p{
                        color:#333333;
                        text-align: left;
                        font-size:12px;
                        margin:9px 0;
                    }
                }
            }
            .editWrap{
                position: absolute;
                right: 1.5%;
                bottom: 7%;
                font-size: 15px;
                font-weight: 500;
                img{
                    width:157px;
                    margin-bottom: -1px;
                    &:nth-of-type(2){
                        display: none;
                    }
                }
                // &:hover{
                //     color:$purple;
                //     img{
                //         &:nth-of-type(1){
                //             display: none;
                //         }
                //         &:nth-of-type(2){
                //             display: inline-block;
                //         }
                //     }
                // }
            }
            .shareWrap{
                position: absolute;
                right: 1.5%;
                top: 5%;
            }
        }
        .mb20{
            margin-bottom: 20px;
        }
    }
</style>