<template>
    <div class="vendorAccount" :class="{display:!editStatus}">
        <LoadingSaving v-show="loading"/>
        <img class="editAccount btn" v-if="!editStatus" @click="changeStatus(true)" src="@/assets/img/editAccount.svg" alt="">
        <div class="bg" v-if="editModelPop">
            <div class="uploadModelPop">
                <h4>Edit model <span class="btn" @click="editModelPop=false"></span></h4>
                <div class="inputWrap">
                    <label for="modelname">Model name</label>
                    <input type="text" id="modelname" name="modelname" placeholder="Enter your model name" v-model="editingModelName">
                </div>    
                <div class="container">
                    <img src="@/assets/img/uploadPreview.png" alt="">
                    <div class="uploadModelImg">
                        <label for="upload-model-input" id="upload-model-photo" :style="{backgroundImage: 'url(' + uploadModelDefault + ')'}"></label>
                        <input type="file"  @change="modelImageChange" id="upload-model-input">
                    </div>
                    <div class="item">
                        <h4>Preview image</h4>
                        <p>Size：500 *500</p>
                        <p>File format：PNG、JPG</p>
                    </div>
                </div>
                <div class="btnWrap">
                    <button class="cancel" @click="cancelUploadModel">Cancel</button>
                    <button class="submit" @click="uploadModelShowImg">Save</button>
                </div>
            </div>
        </div>
        <div class="bg" v-show="emailConfirm">
            <div class="emailConformPop">
                <h4>Please verify your mailbox</h4>
                <ul style="list-style-type: decimal;list-style-position: inside;">
                    <li>Go to the mailbox you filled in and open the verification letter, the subject of the letter is : Amazon Web Services – Email Address Verification Request in region Asia Pacific (Tokyo)</li>
                    <li>Click the link to complete the verification</li>
                    <li>Come back here and click button"I have completed the mailbox verification"</li>
                    <li>Please click the button “Save" to complete the sign up application.</li>
                </ul>
                <button class="submit" @click="emailConfirm=false">I have completed the mailbox verification</button>
            </div>
        </div>
        <div class="informationForm form">
            <h3>Account</h3>
            <div class="title mb40">Account settings</div>
            <div class="formContainer">
                <div class="inputContainer">
                    <!-- <div class="inputWrap">
                    <div class="inputWrap" v-show="!editType=='adminEdit'">
                        <label for="account">Account</label>
                        <input v-model="form.account" name="account" id="account" type="text" placeholder="Enter account number">
                    </div> -->
                    <div class="inputWrap">
                        <label for="taxid"><span class="mark">*</span>Account ID</label>
                        <input v-model="form.taxId" name="taxid" id="taxid" placeholder="Enter your Account ID" :disabled="true" sytle="background-color: inherit;border: none;padding-left: 0;">
                    </div>
                    <!-- <div class="inputWrap" v-show="editType=='adminEdit'">
                        <label for="taxid">Tax ID number</label>
                        <input v-model="form.taxId" name="taxid" id="taxid" type=number placeholder="Enter Tax ID number">
                    </div> -->
                    <div class="inputWrap">
                        <label for="password"><span class="mark">*</span>Password</label>
                        <input name="password" id="password" type="password" v-model="form.password" placeholder="Password length: 8-12 characters" :disabled="!editStatus">
                    </div>
                    <div class="inputWrap" v-if="editStatus">
                        <label for="confirmPassword"><span class="mark">*</span>Confirm Password<span>{{passwordCheckHint}}</span></label>
                        <input v-model="confirmpassword" @input="vailed" :class="{error:passwordCheck}" name="confirmPassword" id="confirmPassword" type="password" placeholder="Enter password again" :disabled="!editStatus">
                    </div>
                    <button class="submit update" :class="{error:passwordCheck}" :disabled="passwordCheck" v-if="(confirmpassword!=''&&form.password!='')&&(editType=='edit'||editType=='adminEdit')" @click="updatePassword">Update Settings</button>
                </div>
            </div>
        </div>
        <div class="informationForm form">
            <div class="title">Information</div>
            <div class="formContainer">
                <div class="avatarWrap">
                    <div class="userAvatar" :style="{backgroundImage: 'url(' + userImg + ')'}">
                        <!-- <img for="upload-photo" id="upload-photo-label" src="@/assets/img/icon/btnCamera.svg" alt=""> -->
                        <label for="upload-photo" id="upload-photo-label"></label>
                        <input type="file" @change="fileChange" id="upload-photo">
                    </div>
                    <p>Avatar</p>
                </div>
                <div class="inputContainer">
                    <div class="inputWrap">
                        <label for="name"><span class="mark">*</span>Name</label>
                        <input v-model="form.companyName" name="name" id="name" type="text" placeholder="Enter Name" :disabled="!editStatus">
                    </div>
                    <!-- <div class="inputWrap">
                        <label for="intro"><span class="mark">*</span>Introduction</label>
                        <input v-model="form.introduction" name="intro" id="intro" type="text" placeholder="Enter Introduction">
                    </div> -->
                    <!-- <div class="inputWrap" v-show="!editType=='adminEdit'">
                        <label for="taxid">Tax ID number</label>
                        <input v-model="form.taxId" name="taxid" id="taxid" type=number placeholder="Enter Tax ID number">
                    </div> -->
                </div>
                <div class="inputContainer">
                    <div class="inputWrap">
                        <label for="email"><span class="mark">*</span>E-mail</label>
                        <input v-model="form.email" name="email" id="email" type="email" placeholder="Fill in the e-mail" :disabled="!editStatus">
                    </div>
                    <div class="inputWrap">
                        <label for="phone"><span class="mark">*</span>Phone</label>
                        <input v-model="form.phone" name="phone" id="phone" type="number" placeholder="PFill in the phone number" :disabled="!editStatus">
                    </div>
                    <div class="inputWrap">
                        <label for="address"><span class="mark">*</span>Address</label>
                        <input v-model="form.address" name="address" id="address" type="text" placeholder="Fill in the contact address" :disabled="!editStatus">
                    </div>
                    <div class="inputWrap">
                        <label for="link"><span class="mark" style="visibility:hidden;">*</span>Link</label>
                        <input v-model="form.link" name="link" id="link" type="text" placeholder="The link of your website、 fan page, etc." :disabled="!editStatus">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="informationForm form uploadForm" v-if="editType=='edit'||editType=='adminEdit'">
            <div class="title">Customized model</div>
            <div class="uploadContainer">
                <div class="uploadWrap btn" ref="div">
                    <img src="@/assets/img/uploadModel.png" alt="">
                    <img src="@/assets/img/uploadModelA.png" alt="">
                    <p>{{alertMessage}}</p>
                    <label class="btn">
                        <strong v-if="progress === 0">
                        {{ label }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </strong>
                        <strong v-if="progress > 0 && progress < 100"> {{ progress }}%</strong>
                        <span v-else>{{ name }}</span>
                        <input ref="input" type="file" :accept="accept" @change="onFileChange"/>
                    </label>
                    <svg v-if="progress === 100" @click="removeFile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <div class="uploadItem btn" v-for="(item,index) in sellerModel" :key="'uploadItem'+index" :class="{active:activeModel==index}" @click="activeModel=index">
                    <div class="previewImg" :class="{noImg:item.imageUrl==''}" :style="{backgroundImage: 'url(' + item.imageUrl + ')'}"></div>
                    <h4 v-if="item.name">{{item.name}}</h4>
                    <h4 v-if="!item.name">No Name</h4>
                    <img class="editInfo btn" @click="openEdit" src="@/assets/img/icon/editInfo.png" alt="">
                    <img class="deleteModel btn" @click="deleteModel" src="@/assets/img/icon/deleteModel.png" alt="">
                </div>
            </div>
        </div> -->
        <div class="infoTrigger">
            <img class="btn" @click="questionOpen=true" src="@/assets/img/icon/question.svg" alt="">
            <el-checkbox v-model="checked" @change="checkChange" >Show contact information</el-checkbox>
        </div>
        <div class="btnWrap" v-if="editStatus">
            <button class="cancel" @click="changeStatus(false)">Cancel</button>
            <button class="submit" @click="updateData">Save</button>
        </div>
        <div class="changeSuccess" v-if="successAlert">
            <img src="@/assets/img/icon/locker.png" alt="">
            <h4>Password updated<br>successfully !</h4>
        </div>
    </div>
</template>
<script>
import headIcon from "@/assets/img/icon/userIcon.svg";
import uploadItem from "@/assets/img/uploadItem.png";
import uploadDefault from "@/assets/img/uploadPreview.png";
import {updateUserAccByAdmin,getUserAccByAdmin,vendorSignup,updateAvatar,updateSellerPassword,getSellerModel,uploadModelImg,uploadSellerModel,deleteSellerModel,updateModelData,getSellerProfileForAdmin,HostUrl,updateSellerProfile,uploadSellerInfoOpen} from '@/api';
import {mapGetters,mapActions} from "vuex";
import LoadingSaving from "@/components/LoadingSaving";

export default {
    data(){
        return{
            uploadData:[
                {
                    img:uploadItem,
                    name:'Model name'
                }
            ],
            hostUrl: HostUrl,
            alertMessage:'The file weight more than 10 MB',
            form:{
                nickname:'',
                account: '',
                password: '',
                avatarId:'',
                introduction: '',
                companyName: '',
                taxId: '',
                email: '',
                phone: '',
                address: '',
                link: '',
                avatarUrl:'',
            },
            // passwordCheckHint: '',
            confirmpassword:'',
            avatar:new FormData(),
            passwordCheck:false,
            editModelPop:false,
            successAlert:false,
            file: null,
            name: null,
            progress: 0,
            error: null,
            dragAndDropCapable: true,
            modelImg:new FormData(),
            modelImgPreview:'',
            prepareUploadModel:new FormData(),
            file: '',
            editingModelName:'',    
            editingModelId:'',
            activeModel:null,
            adminTargetSellerData: {},
            uploadImgUrl:'',
            loading:false,
            emailConfirm:false,
            questionOpen:false,
        }
    },
    components:{
        LoadingSaving
    },
    props:{
        editType:{
            default:'edit'
        },
        label: {
            type: String,
            default: 'Drag and Drop'
        },
        accept: {
            type: String,
            default: '.glb' // image/png, image/jpeg, application/pdf, application/vnd.ms-excel
        },
        checked: {
            type:Boolean,
            default:false
        },
        editStatus:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        ...mapGetters(["getAvatar","vendor","customer","sellerModel"]),
        userImg(){
            if(this.getAvatar.url)
                return this.getAvatar.url
            else if(this.vendor.avatarUrl)
                return this.vendor.avatarUrl
            else if(this.adminTargetSellerData.avatarUrl)
                return this.adminTargetSellerData.avatarUrl
            else 
                return headIcon
        },
        bread(){
            if(this.editType=='edit'){
                return ['My exhibition','/',`${this.vendor.companyName}`]
            }
            else if(this.editType=='adminEdit'){
                return ['Manage customers','/','Bkhole']
            }
            else if(this.editType=='signup'){
                return ['Login page','/','Signup']
            }else if(this.editType=='adminSignup'){
                return ['Manage customers','/','Add new customer']
            }
        },
        uploadModelDefault(){
            if(this.modelImgPreview!=''&&this.modelImgPreview!=undefined){
                return this.modelImgPreview
            }else{
                return uploadDefault
            }
        },
        passwordCheckHint(){
            if(this.form.password){
                if(this.form.password.length < 8){
                    return 'password length must be more than 8';
                }
                else if(this.form.password.length > 12){
                    return 'password length must be less than 12';
                }
                else if(this.confirmpassword!=this.form.password){
                    return 'please enter same password';
                }
                else{
                    return '';
                }
            }
            else{
                return '';
            }
        }
    },
    created(){
        if(this.editType=='edit'){
            this.form.account = this.customer.account
            this.form.avatarId = this.customer._id
            this.form.nickname = this.customer.nickname
            this.form.introduction = this.vendor.introduction
            this.form.taxId = this.customer.account
            this.form.phone = this.vendor.phone
            this.form.link = this.vendor.link
            this.form.email = this.vendor.email
            this.form.companyName = this.vendor.companyName
            this.form.avatarUrl = this.vendor.avatarUrl
            this.form.address = this.vendor.address
            this.form.password = '**********';
        }
        else if(this.editType=='adminEdit'){
            this.getSellerProfileForAdminData();
        }
    },
    mounted() {
        this.getSellerModel()
        let vm  = this
            window.addEventListener('keydown', function(e) {
                if (e.key === 'Enter') {
                // code for enter
                    vm.updateData()
                }
        });
    },
    methods: {
        ...mapActions(['setAvatarData','setLogin','setSellerModel','clearAvatarData']),
        backExh(idx){
            if(idx==0){
                this.backPage()
            }
        },
        changeStatus(bool){
            this.$emit('changeEditStatus',bool);
            
        },
        fileChange(e){
            let vm = this
            this.loading = true
            const data = URL.createObjectURL(e.target.files[0]);
            this.avatar.append('avatar', e.target.files[0]) //放進上傳的檔案
            this.setAvatar()
            setTimeout(function(){
                vm.loading = false
            },1000);
        },
        modelImageChange(e){
            const data = URL.createObjectURL(e.target.files[0]);
            this.modelImgPreview = data
            this.modelImg = new FormData()
            this.modelImg.append('image', e.target.files[0]) //放進上傳的檔案
        },
        async getSellerProfileForAdminData(){
            let sellerId = this.$route.query.sid;
            let res = await getSellerProfileForAdmin(sellerId);
            this.adminTargetSellerData = res.data.data;
            // this.form.account = this.adminTargetSellerData.account;
            this.form.avatarId = this.adminTargetSellerData._id;
            this.form.introduction = this.adminTargetSellerData.introduction;
            //this.form.taxId = this.adminTargetSellerData.taxId;
            this.form.phone = this.adminTargetSellerData.phone;
            this.form.link = this.adminTargetSellerData.link;
            this.form.email = this.adminTargetSellerData.email;
            this.form.companyName = this.adminTargetSellerData.companyName;
            this.form.avatarUrl = this.adminTargetSellerData.avatarUrl;
            this.form.address = this.adminTargetSellerData.address;

            let res2 = await getUserAccByAdmin(sellerId);
            this.form.taxId = res2.data.data.account;
            this.form.password = '**********';
        },
        async updateSellerProfileData(){
            let param = {}
            if(this.editType=='edit'){
                param = {
                    introduction: this.form.introduction,
                    companyName: this.form.companyName,
                    taxId: this.form.taxId,
                    email: this.form.email,
                    phone: this.form.phone,
                    address: this.form.address,
                    link: this.form.link,
                    avatarUrl: this.form.avatarUrl,
                }
            }
            if(this.editType=='adminEdit'){
                param = {
                    introduction: this.form.introduction,
                    companyName: this.form.companyName,
                    taxId: this.form.taxId,
                    email: this.form.email,
                    phone: this.form.phone,
                    address: this.form.address,
                    link: this.form.link,
                    avatarUrl: this.form.avatarUrl,
                }
            }
            let res = null;
            if(this.editType=='edit'){
                res = await updateSellerProfile(param, localStorage.getItem('userId'));
            }
            if(this.editType=='adminEdit'){
                res = await updateSellerProfile(param, this.$route.query.sid);
            }
            if(res.data.status == 200){
                this.$message({
                    showClose: true,
                    message: `Seller Data Updated Successfully!`,
                    type: 'success',
                    duration: 1500,
                    onClose: ()=>{
                        // if(this.editType=='edit')
                        //     window.location.href = location.protocol+'//'+window.location.host+'/vendor';
                        // if(this.editType=='adminEdit')
                        //     window.location.href = location.protocol+'//'+window.location.host+'/admin';
                    }
                });
            }
        },
        async updatePassword(){
            let vm = this;
            // let param = {
            //     nickname: this.customer.nickname,
            //     password: this.confirmpassword,
            //     email:this.vendor.email
            // };
            // let res = await updateSellerPassword(param);
            // if(res.data.status == 200){
            //     this.successAlert = true;
            //     window.setTimeout(( () => vm.successAlert=false ), 1000);
            //     this.confirmpassword = '';
            //     this.form.password = '';
            // }
            if(this.editType==='edit'){
                this.form.nickname = this.form.companyName
                let param = {
                    nickname: 'aaron1',
                    password: this.confirmpassword,
                    email:this.vendor.email
                };
                let res = await updateSellerPassword(param);
                if(res.data.status == 200){
                    this.successAlert = true;
                    window.setTimeout(( () => vm.successAlert=false ), 1000);
                    this.confirmpassword = '';
                    this.form.password = '**********';
                }
            }
            if(this.editType==='adminEdit'){
                this.form.nickname = this.form.companyName
                let param = {
                    nickname: this.form.nickname,
                    password: this.confirmpassword,
                    email: this.form.email,
                };
                let res = await updateUserAccByAdmin(param,this.$route.query.sid);
                if(res.data.status == 200){
                    this.successAlert = true;
                    window.setTimeout(( () => vm.successAlert=false ), 1000);
                    this.confirmpassword = '';
                    this.form.password = '**********';
                }
            }
        },
        async updateData(){
            let vm  = this 
            if(this.editType=='signup'||this.editType=="adminSignup"){
                if(!this.form.password){
                    vm.$message({
                        showClose: true,
                        message:`required field need to fill`,
                        type: 'warning',
                        duration: 1500,
                    });
                }else{
                    this.form.password = this.confirmpassword
                    this.form.nickname = this.form.companyName
                    this.form.account = this.form.taxId
                    let res = await vendorSignup(this.form).catch(function (error) {
                        if (error.response) {
                            if(error.response.status==422||error.response.status==404||error.response.status==409||error.response.status==422){
                                let errorlist = Object.keys(error.response.data.data.validatorErrors)
                                let errorMesssage = ''
                                errorlist.forEach((item,idx)=>{
                                    
                                    if(idx == errorlist.length-1){
                                        errorMesssage = errorMesssage + `${item}:${error.response.data.data.validatorErrors[item]}`
                                    }else{
                                        errorMesssage = errorMesssage + `${item}:${error.response.data.data.validatorErrors[item]} / `
                                    }
                                })
                                vm.$message({
                                    showClose: true,
                                    message:`${errorMesssage}`,
                                    type: 'warning',
                                    duration: 2000,
                                });
                            }
                            if(error.response.status==403){
                                if(error.response.data.data.showUnverifiedEmailPopup)
                                    vm.emailConfirm = true
                            }
                            return error.response.status
                        }
                    })
                    if(res.data.status!=201&&res.data.status!=200)return
                    this.clearAvatarData()
                    // if(this.editType=="adminSignup"){
                    //     window.location.href = location.protocol+'//'+window.location.host+'/admin';
                    // }else{
                    //     //this.setLogin(res.data.data)
                    //     window.location.href = location.protocol+'//'+window.location.host+'/vendor/login?login=close';
                    // }
                }
            }
            if(this.editType=='adminEdit'){
                this.updateSellerProfileData();
            }
            if(this.editType=='edit'){
                this.updateSellerProfileData();
            }
        },
        async setAvatar(){
            let res = await updateAvatar(this.avatar);
            let data = {
                        'url':res.data.data.url,
                        'id':res.data.data._id
                        };
            this.form.avatarUrl = res.data.data.url;
            this.form.avatarId = res.data.data._id;
            this.setAvatarData(data);
        },
        vailed(){
            if(!this.form.password.length){
                // this.passwordCheckHint = '';
                this.passwordCheck = true;
            }
            else if(this.form.password.length < 8){
                this.passwordCheck = true;
                // this.passwordCheckHint = 'password length must be more than 8';
            }
            else if(this.form.password.length > 12){
                this.passwordCheck = true;
                // this.passwordCheckHint = 'password length must be less than 12';
            }
            else if(this.confirmpassword!=this.form.password){
                this.passwordCheck = true;
                // this.passwordCheckHint = 'please enter same password';
            }
            else
            {
                this.passwordCheck = false;
            }
        },
        async getSellerModel(){
            if(this.editType!='signup'){
                let res = await getSellerModel(this.$route.query.sid)
                if(res.data.status==200){
                    this.setSellerModel(res.data.data)
                }
            }
        },
        async updateModelDataFunction(){
            let param = {
                name: this.editingModelName,
	            imageUrl: this.uploadImgUrl
            }
            let res
            this.loading = true
            if(this.editType=='adminEdit'){
                res = await updateModelData(param,this.editingModelId,this.$route.query.sid)
            }else {
                res = await updateModelData(param,this.editingModelId)
            }
            this.loading = false
            this.getSellerModel()
            this.cancelUploadModel()
            this.editModelPop = false
        },
        async uploadSellerModelFuction(){
            let vm = this
            let res = null
            this.loading = true
            if(this.editType=='adminEdit'){
                res = await uploadSellerModel(this.prepareUploadModel,this.$route.query.sid)
            }else{
                res = await uploadSellerModel(this.prepareUploadModel)
            }
            if(res.data.status == 201){
                this.loading = false
                vm.getSellerModel()
                vm.editModelPop = true
                vm.modelImgPreview = res.data.data.imageUrl
                vm.editingModelName = res.data.data.name
                vm.editingModelId = res.data.data._id
                this.$refs.input.value = ''
            }
        },
        backPage(){
            if(this.editType=='edit'){
                this.$router.push({name:'VendorHome'})
            }else if(this.editType=='adminEdit'||this.editType=='adminSignup'){
                this.$router.push({name:'Admin'})
            }else if(this.editType=='signup'){
                this.$router.push({name:'VendorLogin'})
            }else{
                this.$router.push({name:'Login'})
            }
        },
        isDragAndDropCapable () {
            const { div } = this.$refs
            return (('draggable' in div)
                || ('ondragstart' in div && 'ondrop' in div))
                && 'FormData' in window
                && 'FileReader' in window
        },
        onFileChange(e) {
            // e.preventDefault()
            let vm = this
            const files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.createFile(files[0])
        },
        createFile(file) {
            const reader = new FileReader()
            const vm = this
            reader.onprogress = e => {
                if (e.lengthComputable) {
                vm.trackProgress(e.loaded, e.total)
                }
            }
            reader.onloadend = e => {
                const { error } = e.target
                if (error != null) {
                    switch (error.code) {
                        case error.ENCODING_ERR:
                        vm.error = 'Encoding error!'
                        break
                        case error.NOT_FOUND_ERR:
                        vm.error = 'File not found!'
                        break
                        case error.NOT_READABLE_ERR:
                        vm.error = 'File could not be read!'
                        break
                        case error.SECURITY_ERR:
                        vm.error = 'Security issue with file!'
                        break
                        default:
                        vm.error = 'I have no idea what\'s wrong!'
                    }
                }
                vm.trackProgress(e.loaded, e.total)
            }
            reader.onload = e => {
                const { result } = e.target
                vm.file = result
                // this.$emit('load', result)
                vm.name = file.name
                vm.editingModelName = vm.name
                console.log(e)
                const json = JSON.stringify(result);
                const blob = new Blob([json], {
                    type: 'application/json'
                });
                vm.prepareUploadModel = new FormData()
                vm.prepareUploadModel.append('model', file)
                vm.uploadSellerModelFuction()
            }
            reader.readAsBinaryString(file)
        },
        trackProgress (loaded, total) {
            this.progress = parseInt(((loaded / total) * 100), 10)
        },
        removeFile () {
            this.progress = 0
            this.file = ''
            this.name = null
            // this.$emit('load', null)
        },
        cancelUploadModel(){
            this.removeFile()
            this.editingModelName = ''
            this.modelImgPreview = ''
            this.editingModelId = ''
            this.editModelPop = false
        },
        async uploadModelShowImg(){
            let res
            if(this.editType=='adminEdit'){
                res = await uploadModelImg(this.modelImg,this.$route.query.sid)
            }else{
                res = await uploadModelImg(this.modelImg)
            }
            this.uploadImgUrl = res.data.data.url
            this.updateModelDataFunction()
        },
        async deleteModel(){
            let res 
            if(this.editType=='adminEdit'){
                res = await deleteSellerModel(this.sellerModel[this.activeModel]._id,this.$route.query.sid)
            }else{
                res = await deleteSellerModel(this.sellerModel[this.activeModel]._id)
            }
            this.getSellerModel()
        },
        openEdit(){
            this.editModelPop=true
            this.editingModelName = this.sellerModel[this.activeModel].name
            this.modelImgPreview = this.sellerModel[this.activeModel].imageUrl
            this.editingModelId = this.sellerModel[this.activeModel]._id
        },
        async checkChange(){
            let param = {
                showDetail:this.checked
            }
            let res = await uploadSellerInfoOpen(param)
        },
    },
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    input[type="file"] {
        display: none;
    }
    input:disabled {
        background-color: #E8E8E8;
    }
    strong,
    svg {
        vertical-align: middle;
    }

    .vendorAccount{
        padding-bottom:88px;
        min-width: 900px;
        position:relative;
        .editAccount{
            position:absolute;
            left: 9%;
            top: 0;
        }
        &.display{
            .inputWrap input{
                background-color: inherit;
                border: none;
                padding-left: 0;
            }
        }
        label{
            font-weight: 500;
            display: block;
            text-align: left;   
            font-size: 18px;
            margin-bottom: 10px;
            position: relative;
            text-indent: -7px;
            span{
                position: absolute;
                right:0;
                bottom:0;
                font-size: 12px;
                color: #FF4CAD;
                font-weight: 400;
            }
            @include for-size(desktop-only) {
                font-size:14px;
            }
            .mark{
                position:static;
                font-size: 16px;
            }
        }
        h3{
            font-size: 18px;
            text-align: left;
            margin-bottom: 40px;
            margin-top: 25px;
        }
        .header{
            margin-bottom: 2%;
        }
        .bread{
            text-align: left;
            margin-left: 3.6%;
            font-size: 16px;
            margin-bottom: 50px;
            font-weight:400;
            span{
                margin-right: 10px;
                &.btn{
                    &:hover{
                        color:$purple;
                    }
                }
            }
        }
        .form{
            margin-bottom: 40px;
        }
        .uploadForm{
            margin-bottom: 6.9%;
        }
        .uploadContainer{
            padding-left: 20px;
            display: flex;
            flex-wrap: wrap;
        }
        .uploadItem{
            background-color: #fff;
            margin-right: 4.6%;
            width: 18.9%;
            padding-top: 1.3%;
            border-radius: 9px;
            position:relative;
            min-width: 215px;
            margin-bottom: 30px;
            .editInfo,.deleteModel{
                display: none;
            }
            &.active{
                border:1px solid #6F52ED;

                .editInfo{
                    position:absolute;
                    width: 32.9%;
                    bottom: -15%;
                    right: 33%;
                    display: block;
                }
                .deleteModel{
                    position:absolute;
                    width: 29%;
                    bottom: -15%;
                    right: 3%;
                    display: block;
                }
            }
            &:nth-of-type(4n){
                margin-right: 0;
            }
            img{
                width: 87%;
            }
            .previewImg{
                width: 87%;
                padding-bottom: 56%;
                background-color:#6F52ED;
                position:relative;
                margin:0 auto;
                background-size: 100%;
                background-position: center;
                &.noImg{
                    &:before{
                        content:'No Preview Image';
                        display: block;
                        color:#fff;
                        font-size: 14px;
                        top:50%;
                        left:50%;
                        transform:translate(-50%,-50%);
                        position:absolute;
                    }
                }
            }
            h4{
                font-size: $font*20;
                font-weight: 500;
            }
        }
        .uploadWrap{
            width: 18.9%;
            margin-right: 4.6%;
            position: relative;
            min-width: 215px;
            margin-bottom: 30px;
            cursor: pointer;
            img{
                width: 100%;
                &:nth-of-type(2){
                    display: none;
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                }
            }
            &:hover,&.dragover{
                img{
                    &:nth-of-type(1){
                        visibility: hidden;
                    }
                    &:nth-of-type(2){
                        display: block;
                    }
                }
            }
            p{
                color: #FF4CAD;
                position: absolute;
                bottom: 2%;
                left: 50%;
                transform: translateX(-50%);
                width: 88%;
                font-size: $font*16;
                @include for-size(tablet-landscape-only) {
                    font-size: 12px;
                }
            }
            label{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                width: 100%;
                height: 100%;
            }
            svg{
                display: none;
            }
        }
        .title{
            font-size: 16px;
            // border-left: 6px solid #6F52ED;
            text-align: left;
            margin-bottom: 20px;
            font-weight: 500;
            // @include for-size(desktop-only) {
            //     font-size:24px;
            // }
        }
        .mb40{
            margin-bottom: 40px;
        }
        .update{
            width: 161px;
            height:68px;
            font-size: 24px;
            border-radius: 10px;
            margin: 0 0 0 auto;
            display: block;
            margin:0 0 0 auto;
            &.error{
                background-color: rgba($color: #6F52ED, $alpha: 0.4);
                border: 1px solid rgba($color: #6F52ED, $alpha: 0.4);
                cursor: inherit;
            }
            @include for-size(desktop-only) {
                width: 120px;
                height: 51px;
                font-size: 18px;
                border-radius: 6px;
            }
            @include for-size(tablet-landscape-only) {
                width: 100px;
                height: 40px;
                font-size: 14px;
                border-radius: 3px;
            }
        }
        .avatarWrap{
            width: 97px;
            position:relative;
            margin-right: 45px;
            margin-bottom: 30px;
            p{
                font-size: 14px;
                margin-top: 10px;
                font-weight: 500;
            }
        }
        .userAvatar{
            padding-bottom:97px;
            background-size: 100%;
            width: 97px;
            position:relative;
            border-radius: 100%;
            background-position: center;
            #upload-photo-label{
                position:absolute;
                top: 67%;
                right: -3%;
                background-image:url('~@/assets/img/icon/btnCamera.svg');
                width: 30%;
                padding-bottom: 31%;
                background-size: 100%;
                cursor: pointer;
                &:hover{
                    background-image:url('~@/assets/img/icon/btnCameraA.svg');
                }
            }
            #upload-photo {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }
        .inputWrap{
            margin-bottom: 32px;
            width: 500px;
            input{
                font-size: 16px;
                line-height: 2.3;
                padding-left: 15px;
                width: 100%;
                border-color: #333333;
                &:focus{
                    border-color:$purple;
                }
                &.error{
                    border:1px solid #FF4CAD;
                }
                @include for-size(desktop-only) {
                    font-size:14px;
                }
            }
        }
        .btnWrap{
            button{
                //width:244px;
                width: 12.3%;
                font-size:20px;
                line-height: 2.5;
                border-radius: 9px;
                margin: 0 52px;
                @include for-size(desktop-only) {
                    font-size:18px;
                }
                @include for-size(tablet-landscape-only) {
                    border-radius: 6px;
                    font-size: 14px;
                    margin: 0 20px;
                }
            }
        }
        .bg{
            position: fixed;
            top:0;
            bottom:0;
            right:0;
            left:0;
            background-color: rgba($color: #000000, $alpha: 0.5);
            z-index: 99;
        }
        .emailConformPop{
            width:513px;
            border:1px $purple solid;
            border-radius:11px;
            position: relative;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            background-color:#fff;
            padding-bottom: 25px;
            h4{
                font-size: 20px;
                @include for-size(tablet-landscape-only) {
                        font-size: 18px;
                }
            }
            ul{
                text-align: left;
                padding: 0 9% 0 12%;
                
                li{
                    line-height: 1.5;
                    margin-bottom: 15px;
                    font-size: 17px;
                    @include for-size(tablet-landscape-only) {
                        font-size: 14px;
                    }
                    &:nth-last-of-type(1){
                        margin-bottom:25px;
                    }
                }
            }
            button{
                border-radius: 7px;
                color:#fff;
                padding:5px;
                font-size: 17px;
                padding: 10px 15px;
                @include for-size(tablet-landscape-only) {
                    font-size: 14px;
                }
            }
        }
        .infoTrigger{
            //position: absolute;
            // right: 2%;
            // top: 32%;
            text-align: left;
            margin-bottom: 80px;
            width: 230px;
            position: relative;
            padding-left: 16px;
            p{
                color:#767676;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
            img{
                position: absolute;
                right: 0;
                width: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
            ::v-deep .el-switch .el-switch__core{
                width: 92px !important;
                height: 35px;
                border-radius: 30px;
                border-color: #C7C7C8;
                background-color: #C7C7C8;
            }

            ::v-deep .el-switch.is-checked .el-switch__core{
                border-color: $purple;
                background-color: $purple !important;
            }
            ::v-deep .el-switch{
                &:before{
                    content:'OFF';
                    position: absolute;
                    right:15px;
                    font-size: 14px;
                    top: 49%;
                    transform: translateY(-50%);
                    color:$gray;
                    z-index: 2;
                }
            }
            ::v-deep .el-switch.is-checked{
                &:before{
                    content:'ON';
                    position: absolute;
                    right:auto;
                    left:15px;
                    font-size: 14px;
                    top: 49%;
                    transform: translateY(-50%);
                    color:#fff;
                    z-index: 2;
                }
            }
            ::v-deep .el-switch .el-switch__core::after{
                height: 31px;
                width: 31px;
            }
            ::v-deep .el-switch.is-checked .el-switch__core::after{
                margin-left: -32px;
            }
            ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
                background-color: $purple;
                border-color: $purple;
            }
            ::v-deep .el-checkbox__label,::v-deep .el-checkbox__input{
                color:#767676;
                font-weight: 500;
                margin-right: 8px;
            }
            ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
                color:#767676;
            }
            ::v-deep .el-checkbox {
                display: inline-block;
                margin-bottom: 0;
            }
        }
        .uploadModelPop{
            border-radius: 9px;
            overflow: hidden;
            width: 32%;
            position: relative;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            background-color: #fff;
            min-width: 400px;
            padding-bottom: 3%;
            h4{
                font-size: $font*20;
                position:relative;
                background-color: #373B53;
                line-height: 2.3;
                color:#fff;
                margin-top: 0;
                margin-bottom: 7%;
                @include for-size(desktop-only) {
                    font-size: 14px;
                }
                span{
                    background-image:url('~@/assets/img/icon/crossW.svg');
                    background-size: 100%;
                    width: 3%;
                    padding-bottom: 3%;
                    position: absolute;
                    top: 50%;
                    right: 2%;
                    transform:translateY(-50%);
                    background-position: center;
                }
            }
            .container{
                display: flex;
                padding: 0 21%;
                margin-bottom: 17%;
                img{
                    display: none;
                    width: 45.2%;
                    margin-right: 4%;
                }
                .uploadModelImg{
                    width: 45.2%;
                    margin-right: 4%;
                    #upload-model-photo{
                        display:block;
                        width:100%;
                        // background-size: 100%;
                        background-size: cover;
                        padding-bottom: 100%;
                        cursor: pointer;
                        background-position: center;
                        background-color:#6F52ED;
                    }
                    #upload-model-input{
                        opacity: 0;
                        position: absolute;
                        z-index: -1;
                    }
                }
                .item{
                    h4{
                        font-size:$font*20;
                        background-color: inherit;
                        text-align: left;
                        margin-bottom: 0;
                        color:#282828;
                        line-height: 2;
                        margin-bottom: 7%;
                        @include for-size(desktop-only) {
                            font-size: 14px;
                        }
                    }
                    p{
                        font-size: 12px;
                        text-align: left;
                        margin-top: 4%;
                        margin-bottom: 0;
                    }
                }
            }
            .inputWrap{
                padding: 0 21%;
                width: 100%;
                label{
                    font-size: $font*18;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                    }
                }
                input{
                    font-size: $font*18;   
                    line-height: 2.5;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                    }
                }
            }
            .btnWrap{
                padding: 0 21%;
                justify-content: space-between;
                display: flex;
                button{
                    font-size: $font*16;
                    width:46%;
                    margin:0;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                        border-radius: 7px;
                    }
                }
            }
        }
        .changeSuccess{
            position: fixed;
            left: 50%;
            top: 50%;
            transform:translate(-50%,-50%);
            background-color: #fff;
            width: 326px;
            height: 325px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 9px;
            box-shadow: 0 5px 11px 0px rgb(0 0 0 / 20%);
            h4{
                font-size: 20px;
            }
        }
    }
</style>